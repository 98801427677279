<template>
    <div class="hhhcont">
        <h1 style="margin-top:100px">欢迎使用BetterSmile®喜雅齿口腔系统</h1>
        <div class="form-css" >
            
            <div class="form-text" style="margin-right:20px">
                会员数：{{ number }}
            </div>       
            <div>
                
                <div class="form-text">订单数：{{ number1 }}</div>
                <DatePicker @on-change="changeTime" v-model="timeVal" type="daterange" placement="bottom-end" placeholder="请选择日期" style="width: 220px"></DatePicker>
            </div>
        </div>
    </div>
</template>

<script>
// 

import { other_count } from "@/api/index";
export default {
    data(){
        return {
            number:0,
            number1:0,
            
            timeVal: [],
            pageForm: {
                start_date: '',
                end_date: ''
            }
        }
    },
    created(){
        // this.get_member_list()
        // this.get_order_list()
        this.getlist()
    },
    methods: {
        getlist(){
            other_count(this.pageForm).then(res => {      
                   this.number = res.data.member || 0
                   this.number1 = res.data.order || 0
            }).catch(res => {
                this.$Message.error(res.msg);
            })
        },
        changeTime(e){
            console.log('e',e)
            this.pageForm.start_date = e[0]
            this.pageForm.end_date = e[1]
            this.getlist()
        }
    }

};
</script>

<style scoped>
.hhhcont {
    width: 100%;
    height: calc(100vh - 104px);
    background: #fff;
    padding:10px;
    text-align: center;
}
.form-css{
    text-align: center; 
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-top:50px;
   
}
.form-text{
    font-size:20px;
}

</style>